import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Link, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Ласкаво просимо до MetroVille
			</title>
			<meta name={"description"} content={"Ваші ворота в міське та приміське життя – MetroVille, де кожна нерухомість розповідає свою історію"} />
			<meta property={"og:title"} content={"Ласкаво просимо до MetroVille"} />
			<meta property={"og:description"} content={"Ваші ворота в міське та приміське життя – MetroVille, де кожна нерухомість розповідає свою історію"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1534889156217-d643df14f14a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="Price-17">
			<Text margin="0px 0px 20px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
				У MetroVille Realty ми пропонуємо низку послуг, розроблених спеціально для того, щоб провести вас у міських і приміських ландшафтах, гарантуючи, що ваша подорож до ідеального будинку буде настільки ж легкою, як і захоплюючою.
			</Text>
			<Text margin="0px 50% 20px 0px" font="normal 900 48px/1.2 --fontFamily-serifGaramond" lg-margin="0px 0 20px 0px">
				Особливості обслуговування
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-4.jpg?v=2024-02-09T09:45:45.535Z"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-4.jpg?v=2024-02-09T09%3A45%3A45.535Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-4.jpg?v=2024-02-09T09%3A45%3A45.535Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-4.jpg?v=2024-02-09T09%3A45%3A45.535Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-4.jpg?v=2024-02-09T09%3A45%3A45.535Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-4.jpg?v=2024-02-09T09%3A45%3A45.535Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-4.jpg?v=2024-02-09T09%3A45%3A45.535Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-4.jpg?v=2024-02-09T09%3A45%3A45.535Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Міські квартири
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Відкрийте для себе стильні сучасні апартаменти в жвавих центрах міста, що пропонують зручність і доступ до жвавого міського життя.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/2-1.jpg?v=2024-02-09T09:45:45.530Z"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/2-1.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/2-1.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/2-1.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/2-1.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/2-1.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/2-1.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/2-1.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Чарівні таунхауси
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Відкрийте для себе затишні та мальовничі таунхауси, розташовані в оригінальних районах, які пропонують ідеальне поєднання комфорту та характеру.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-2.jpg?v=2024-02-09T09:45:45.534Z"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-2.jpg?v=2024-02-09T09%3A45%3A45.534Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-2.jpg?v=2024-02-09T09%3A45%3A45.534Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-2.jpg?v=2024-02-09T09%3A45%3A45.534Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-2.jpg?v=2024-02-09T09%3A45%3A45.534Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-2.jpg?v=2024-02-09T09%3A45%3A45.534Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-2.jpg?v=2024-02-09T09%3A45%3A45.534Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/4-2.jpg?v=2024-02-09T09%3A45%3A45.534Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Просторі заміські будинки
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Знайдіть свій приміський відпочинок у просторих будинках, оточених зеленню та спокоєм, що ідеально підходить для сімей і тих, хто шукає спокійного відпочинку.
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="35px 0"
				margin="2rem 0px 0px 0px"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-1.jpg?v=2024-02-09T09:45:45.545Z"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-1.jpg?v=2024-02-09T09%3A45%3A45.545Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-1.jpg?v=2024-02-09T09%3A45%3A45.545Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-1.jpg?v=2024-02-09T09%3A45%3A45.545Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-1.jpg?v=2024-02-09T09%3A45%3A45.545Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-1.jpg?v=2024-02-09T09%3A45%3A45.545Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-1.jpg?v=2024-02-09T09%3A45%3A45.545Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-1.jpg?v=2024-02-09T09%3A45%3A45.545Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Міські гарячі точки
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Дізнайтеся про найпопулярніші райони з модними кафе, культурними пам’ятками та бурхливим нічним життям.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-2.jpg?v=2024-02-09T09:45:45.530Z"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-2.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-2.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-2.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-2.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-2.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-2.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/3-2.jpg?v=2024-02-09T09%3A45%3A45.530Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Приміський мир
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Досліджуйте тихі приміські райони з чудовими школами, парками та сімейними зручностями, ідеальними для тих, хто шукає повільніший темп життя.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-1%20%281%29.jpg?v=2024-02-09T09:45:45.500Z"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-1%20%281%29.jpg?v=2024-02-09T09%3A45%3A45.500Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-1%20%281%29.jpg?v=2024-02-09T09%3A45%3A45.500Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-1%20%281%29.jpg?v=2024-02-09T09%3A45%3A45.500Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-1%20%281%29.jpg?v=2024-02-09T09%3A45%3A45.500Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-1%20%281%29.jpg?v=2024-02-09T09%3A45%3A45.500Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-1%20%281%29.jpg?v=2024-02-09T09%3A45%3A45.500Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c4dc341d712a00232fcf5d/images/1-1%20%281%29.jpg?v=2024-02-09T09%3A45%3A45.500Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						Індивідуальне керівництво
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 400 20px/1.5 --fontFamily-sans" color="#808890">
						Отримайте персональні поради від наших досвідчених фахівців з нерухомості, які прагнуть зрозуміти ваші унікальні потреби та вподобання.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4" background="linear-gradient(90deg,--color-lightD2 0%,transparent 100%) 0 0 no-repeat">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Контакти
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Зв’яжіться з нами сьогодні, щоб дізнатися більше про наші послуги або розпочати свою міську чи приміську подорож. Наша віддана команда готова допомогти вам на кожному кроці, щоб ваші враження від нерухомості перевершили очікування.
					</Text>
					<Box min-width="10px" min-height="10px" display="flex" sm-flex-direction="column">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							margin="0px 25px 0px 0px"
							sm-margin="0px 0 10px 0px"
						>
							<Link href="tel:+905339274952" color="#000000" text-decoration-line="initial" font="--lead">
								0969519103
							</Link>
						</Box>
						<Box min-width="10px" min-height="10px" display="flex" margin="0px 0 0px 0px">
							<Link href="mailto:hello@luminesto.com" color="#000000" text-decoration-line="initial" font="--lead">
								hello@luminesto.com
							</Link>
						</Box>
					</Box>
					<Button
						margin="1.5rem 0px 0px 0px"
						background="--color-secondary"
						type="link"
						href="/contacts"
						text-align="center"
						text-decoration-line="initial"
					>
						Контакти
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65c4dc341d712a00232fcf5b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});